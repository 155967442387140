import React, { useState } from 'react';

function ContactForm_Info() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Enviando...');
  
    try {
      const response = await fetch('http://localhost:3000/send_mail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // Formato de datos para PHP
        },
        body: new URLSearchParams({
          name: formData.name,
          email: formData.email,
          message: formData.message,
        }),
      });
  
      const result = await response.json();
      if (result.status === 'success') {
        setStatus('Mensaje enviado con éxito');
      } else {
        setStatus('Hubo un error al enviar el mensaje');
      }
    } catch (error) {
      setStatus('Error al enviar el mensaje');
    }
  };
  

  return (
    <div>
      <h1>Contáctanos</h1>
      <div class="card text-left">
        <div class="card-body">
          <h3 class="card-title">Para contactarnos</h3>
          <p class="card-text">Escribenos a <a href='mailto:contacto@scouting.cl'>contacto@scouting.cl</a> si deseas enviarnos videos de jugadas tuyas o de algún conocido para poder publicarlo en el sitio. </p>
          <p class="card-text">Esta pagina está en constante mejora.</p>
          
        </div>
      </div>
    </div>
  );
}

export default ContactForm_Info;
