// src/pages/Nosotros.js
import { motion } from "framer-motion";

function Nosotros() {
  return (
    <motion.div className="background1"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <section className="about-us">
        <div className="about-container">
          <h1>Sobre Nosotros</h1>
          <div className="about-content">
            <div className="about-text">
              <p>
                En Scouting Chile, nuestro objetivo es encontrar y promover los mejores talentos futbolisticos de nuestro país. Creemos en el poder del deporte para transformar vidas y crear oportunidades para todos. 
              </p>
              <p>
                Nuestro proposito y objetivo en un futuro es que Nuestra plataforma conecte a jugadores de todos los niveles con clubes, entrenadores y reclutadores, ayudando a los atletas a dar el siguiente paso en su carrera.
              </p>
              <p>
                Desde jóvenes promesas hasta jugadores experimentados, Scouting Chile es el lugar para mostrar tu talento, mejorar tus habilidades y ser descubierto por los mejores clubes.
              </p>
            </div>
           
          </div>
        </div>
      </section>
    </motion.div>
  );
}

export default Nosotros;
