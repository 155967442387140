import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useState } from "react";
import logo from '../assets/logo.jpeg'; // Asegúrate de tener la imagen del logo en /assets

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Alterna entre abrir y cerrar el menú
  };

  return (
    <motion.header
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ position: 'relative', zIndex: 1000 }} /* Asegura el z-index en el header */
    >
      <nav>
        {/* Menú hamburguesa para pantallas pequeñas */}
        <div className="menu-icon" onClick={toggleMenu}>
          &#9776; {/* Icono de hamburguesa */}
        </div>

        <ul className={menuOpen ? 'active' : ''} style={{ display: 'flex', gap: '1rem', alignItems: 'center', zIndex: 1001 }}>
          <motion.img 
            whileHover={{ scale: 1.2 }} 
            style={{ height: '50px', transformOrigin: 'center' }}
            src={logo} alt="Scouting Chile Logo" 
          />
          <motion.li whileHover={{ scale: 1.2 }} style={{ transformOrigin: 'center' }} onClick={toggleMenu}>
            <Link to="/">Inicio</Link>
          </motion.li>
          <motion.li whileHover={{ scale: 1.2 }} style={{ transformOrigin: 'center' }} onClick={toggleMenu}>
            <Link to="/nosotros">Nosotros</Link>
          </motion.li>
          <motion.li whileHover={{ scale: 1.2 }} style={{ transformOrigin: 'center' }} onClick={toggleMenu}>
            <Link to="/noticias">Noticias</Link>
          </motion.li>
          <motion.li whileHover={{ scale: 1.2 }} style={{ transformOrigin: 'center' }} onClick={toggleMenu}>
            <Link to="/contacto">Contáctanos</Link>
          </motion.li>
        </ul>
      </nav>
    </motion.header>
  );
}

export default Header;
