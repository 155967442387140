import React from 'react';
import { FaFacebook, FaInstagram, FaTiktok, FaTwitter, FaYoutube } from 'react-icons/fa'; // Importamos los íconos

function Footer() {
  return (
    <footer style={footerStyles}>
      <div style={containerStyles}>
        <p>© 2024 Scouting Chile - Todos los derechos reservados.</p>
        <div style={socialContainerStyles}>
          {/* Enlaces a redes sociales */}
          <a href="https://www.facebook.com/scoutingcl" target="_blank" rel="noopener noreferrer" style={iconStyles}>
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/scoutingcl" target="_blank" rel="noopener noreferrer" style={iconStyles}>
            <FaInstagram />
          </a>
          <a href="https://www.youtube.com/@ScoutingChile" target="_blank" rel="noopener noreferrer" style={iconStyles}>
            <FaYoutube />
          </a>
          <a href="https://www.tiktok.com/@scoutingcl" target="_blank" rel="noopener noreferrer" style={iconStyles}>
            <FaTiktok />
          </a>
        </div>
      </div>
    </footer>
  );
}

const footerStyles = {
  backgroundColor: '#0d47a1', // Azul primario
  color: '#fff',
  padding: '1rem',
  textAlign: 'center',
  position: 'relative',
  bottom: 0,
  width: '100%',
};

const containerStyles = {
  maxWidth: '1200px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const socialContainerStyles = {
  display: 'flex',
  gap: '1.5rem',
  marginTop: '1rem',
};

const iconStyles = {
  color: '#fff',
  fontSize: '1.8rem',
  transition: 'color 0.3s',
};

export default Footer;
