// src/pages/Contacto.js
import ContactForm from "../components/ContactForm";
import ContactForm_Info from "../components/ContactForm_Info";

function Contacto() {
  return (
    <div class="section-parallax">
      <ContactForm_Info />
    </div>
  );
}

export default Contacto;
