// src/pages/Home.js
import React from 'react';
import '../styles/styles.css'; // Asegúrate de que los estilos estén bien importados

function Home() {
  return (
    <div>
      <div className="background1">
      <h1>Bienvenido a Scouting Chile</h1>
        <p>Buscamos a los mejores Chilenos para el balón pie, buscamos potenciar el desarrollo del deporte rey en Chile y poder mostrar a gente que nació para ser futbolista y no tiene la visibilidad en este país. ¡Te estamos buscando!</p>
      </div>

      <section className="services">
        <h2>Nuestros Servicios</h2>
        <div className="services-container">
          <div className="card">
            <h3>Scouting Deportivo</h3>
            <h4>Proximamente</h4>
            <p>Conéctate con los mejores talentos deportivos a nivel nacional.</p>
          </div>
          <div className="card">
            <h3>Asesoría para Talentos</h3>
            <h4>Proximamente</h4>
            <p>Brindamos orientación y apoyo a jóvenes talentos para alcanzar sus metas.</p>
          </div>
          <div className="card">
            <h3>Publicación de Talentos</h3>
            <h4>Proximamente</h4>
            <p>Publica videos y perfiles para ser visto por clubes y scouts profesionales.</p>
          </div>
        </div>
      </section>
      <div className="background1">
        <h1>Descubre y Promueve Talentos Deportivos</h1>
        <p>En Scouting Chile, conectamos a talentos jóvenes con los mejores equipos y entrenadores del país. ¡Publica tu perfil y sé visto por clubes profesionales!</p>
        {/* <button className="cta-button">Explora Talentos</button> */}
        </div>
      <section className="news">
        <h2>Últimas Noticias En construcción</h2>
        <div className="news-container">
            <article className="news-item">
            <h3>Nuevo Talento Descubierto en Santiago</h3>
            <h4>Proximamente</h4>
            <p>Conoce la historia de "XXXXXX", un joven futbolista que ha sido fichado por un importante club.</p>
            </article>
            <article className="news-item">
            <h3>Inicia la Temporada de Scouting 2024</h3>
            <h4>Proximamente</h4>
            <p>Prepárate para participar en las próximas sesiones de scouting en todo el país.</p>
            </article>
            <article className="news-item">
            <h3>Publica Tu Perfil</h3>
            <h4>Proximamente</h4>
            <p>Sube tu video y destaca en nuestra plataforma para que los mejores equipos te descubran.</p>
            </article>
        </div>
        </section>
        {/* Video embebido de YouTube (Short) */}
      <div className="background3">
        <h1>Ultimo video subido a YT</h1>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/dFCS0HFw2tk"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
      </div>
    </div>
  );
}

export default Home;
