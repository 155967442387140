// src/pages/Noticias.js
function Noticias() {
  return (
    <div>
      <h1>Noticias</h1>
      <p>Proximamente, aquí encontrarás las últimas noticias del mundo del scouting Futbolistico.</p>
    </div>
  );
}

export default Noticias;
